.create-raffle-page-container {
    /* background: transparent url('../images/Rectangle 49.png') 0% 0% no-repeat padding-box;
    width: 100%;
    display: inline-block;
     background: #12101000 linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box;
    top: 0px;
    left: 0px;  */
}

.stepper.stepper-pills.stepper-column .stepper-wrapper {
    display: flex;
    align-items: center;
}

.form-control.form-control-solid {
    border-color: #3f4254;
}

#dao:checked+label,
#individual:checked+label {
    background-color: #dcfdfd;
}

.input-hidden {
    position: absolute;
    left: -9999px;
}

input[type=radio]:checked+label>img {
    border: 1px solid #fff;
    box-shadow: 0 0 3px 3px #172e69;
}


/* Stuff after this is only to make things more pretty */

input[type=radio]+label>img {
    border-radius: 20px;
    width: 150px;
    height: 150px;
    transition: 500ms all;
}

input[type=radio]:checked+label>img {
    transform: rotateZ(-10deg) rotateX(10deg);
}

.raffle-price-text {
    border-radius: 10px 0px 0px 10px;
}

.raffle-price-select {
    height: 51px;
    border-radius: 0px 10px 10px 0px;
    border-color: #3f4254 !important;
    font-size: 18px;
}

.raffle-price-type-select {
    height: 51px;
    border-radius: 10px 10px 10px 10px;
    border-color: #3f4254 !important;
    font-size: 18px;
}

.card {
    background: #2a3042;
}

.form-select.form-select-solid,
.form-select.form-select-solid:active {
    background-color: #222736 !important;
    border-color: #222736 !important;
    color: #fff !important;
}

.form-control.form-control-solid,
.form-control.form-control-solid:active {
    background-color: #222736 !important;
    color: #fff !important;
}

.btn-check:checked+.btn.btn-active-light-primary {
    background-color: #1097CD !important;
}

.btn.btn-active-light-primary:hover:not(.btn-active) {
    background-color: #1097CD !important;
}

.wl-token-name {
    word-break: break-all;
}

.create-raffle-card {
    background: #181426 !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
    padding: unset;
    width: 1000px !important;
}

.create-raffle-label,
.create-raffle-label span {
    font-size: 18px !important;
    color: #a1a5b7 !important;
    font-weight: 500;
}

.create-raffle-btn {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.create-raffle-btn:hover {
    color: #fff !important;
}

.modal-content {
    background: #181426 !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
    padding: unset;
}

.modal-title {
    color: #fff;
    font-size: 20px;
}

.modal-header {
    border-bottom: 1px solid #3f4254;
}

.btn-close {
    color: #fff;
    background: url(../images/close.png) no-repeat;
}

.modal-btn-submit {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 15px;
}

.modal-btn-cancel {
    background: #222736;
    color: #fff;
    font-size: 15px;
}

.raffle-image-upload input {
    width: 150px;
    height: 50px;
    font-size: 15px;
    background: #222736;
    border: unset;
}

.msg-note {
    color: #FB9328;
    font-size: 16px;
}

.sub-note {
    font-size: 15px;
    color: #7e8299;
}

.token-modal .modal-content,
.nft-modal .modal-content {
    min-height: 600px;
}

.modal-footer {
    padding: 0rem 2rem 1rem 1rem;
    border-top: 1px solid #3f4254;
}

#file-input {
    color: #fff;
}

.swal2-popup {
    background: #181426 !important;
}

.f-size-30 {
    font-size: 30px;
    font-weight: 600;
}

.f-size-40 {
    font-size: 40px;
    font-weight: 600;
}

.f-size-20 {
    font-size: 18px;
    font-weight: 500;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 750px;
    }
}

.upload-btn-wrapper {
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn {
    border: 2px solid #3f4254;
    color: #fff;
    background-color: #222736;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 18px;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.uploaded-file-name {
    padding-top: 5px;
    color: #fff;
    font-size: 17px;
}

.space {
    padding-left: 4px;
    padding-right: 5px;
}

.reach-us-link,
.reach-us-link:hover {
    color: #05A2EA;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
}

.reach-us-img {
    width: 150px;
}

.swal2-popup .swal2-title {
    color: #fff;
}

.reach-us-to-whitelist {
    color: #fff;
    font-size: 17px;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.file-wrapper {
    width: 210px;
    height: 240px;
    border: 10px solid #222736;
    position: relative;
    /* margin: auto; */
    /* margin-top: 50px; */
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    color: transparent;
    padding: 10px;
}

.file-wrapper:hover {
    background-color: #181c32;
    background-image: none !important;
}

.file-wrapper:after {
    content: '+';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: max-content;
    height: max-content;
    display: block;
    /* max-height: 85px; */
    font-size: 70px;
    font-weight: bolder;
    color: #fff;
}

.file-wrapper:before {
    content: 'Choose NFT For Raffle';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 35px;
    width: max-content;
    height: max-content;
    font-size: 1.2em;
    color: #fff;
}

.file-wrapper:hover:after {
    font-size: 73px;
}

.file-wrapper .close-btn {
    display: none;
}

input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 99999;
    cursor: pointer;
}

.ticket-threshold {
    color: #ffc107;
    font-size: 13px;
    font-weight: 600;
}

.link {
    color: rgba(96, 165, 250, 1) !important;
}

.f-size-15 {
    font-size: 15px;
}

.creator-guide {
    font-size: 17px;
    color: #ffc107;
    font-weight: 600;
    cursor: pointer;
}

.creator-guide i {
    color: #ffc107;
    font-size: 17px;
}

.guide-container {
    padding-top: 5px;
}

.guide-container ol li {
    font-size: 15px;
    color: #fff;
    line-height: 25px;
    padding-bottom: 5px;
}

@media only screen and (max-width: 768px) {
    .raffle-price-text {
        height: 50px;
    }
    .create-raffle-card {
        width: unset !important;
    }
}

.create-raffle-wallet-connet {
    border: 1px solid #3f4254;
    padding: 10px;
    border-radius: 20px;
}

.discord-id {
    color: #5865f2;
}

.dao-detected {
    margin: auto;
    margin-top: 120px;
    border: 1px solid #3f4254;
    padding: 10px;
    border-radius: 20px;
}