.footer {
    position: fixed;
    z-index: 3;
    bottom: 0px;
    width: 100%;
    background: transparent linear-gradient(92deg, #130F1D 0%, #160F1E 100%) 0% 0% no-repeat padding-box;
    border: 0.5px solid #3E3944;
    border-radius: 50px 50px 0px 0px;
}

.powered-by {
    font-size: 17px;
    font-weight: 600;
    color: #ffc107;
}

.powered-by:hover {
    color: #ffc107;
}

.social-icon {
    width: 25px;
    padding: 2px;
}

.social-container {
    display: inline-block;
    cursor: pointer;
    border: 0.125rem solid transparent;
    padding: 3px;
    color: #fefefe;
    transition: all .5s ease;
    background: #0c0a13;
    border-radius: 3px;
    margin-left: 5px;
}

.social-container:hover,
.social-container:focus {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}
